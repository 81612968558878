@media (width >= 1170px) and (width <= 1300px) {
  .header-area .menu-right ul li {
    width: 31px;
  }

  .header-area .menu-right ul li:after {
    height: 26px;
    top: 35px;
  }

  .home-testing .single-team .team-image img {
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .contact-page-area .total-contact-form .contact-information {
    padding: 40px;
  }

  .home-about-area {
    padding: 60px 0;
  }
}

@media (width >= 992px) and (width <= 1169px) {
  .header-area .menu-right ul li {
    width: 31px;
  }

  .header-area .menu-right ul li:after {
    height: 26px;
    top: 35px;
  }

  .header-area .main-menu ul li {
    padding: 35px 22px;
  }

  .related-team-member .related-team .single-team .team-image {
    height: 300px;
  }

  .home-testing .single-team .team-image img {
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .contact-page-area .total-contact-form .contact-information {
    padding: 20px;
  }

  .footer-top .widget .social-media ul li {
    margin: 1px;
  }

  .home-about-area {
    padding: 70px 0;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .container {
    width: 750px;
  }

  .page-header-area .title1 h1 {
    width: 25em;
    font-size: 1.5rem;
  }

  .header-area .menu-right ul li {
    width: 31px;
  }

  .header-area .menu-right ul li:after {
    height: 26px;
    top: 35px;
  }

  .header-area .main-menu ul li {
    padding: 22px;
  }

  .menu-right {
    display: none;
  }

  .header-area {
    position: inherit;
    background: #000;
  }

  .header-area .logo-area a {
    padding: 5px;
  }

  .related-team-member .related-team .single-team .team-image {
    height: 370px;
  }

  .home-testing .single-team .team-image img {
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .contact-page-area .total-contact-form .contact-information {
    padding: 30px;
  }

  .footer-top .widget .social-media ul li {
    margin: 1px;
  }

  .solution-page, .solution-page .single-team-content-area .team-information ul {
    text-align: center;
  }

  .footer-top .widget.single-footer2 ul li {
    margin: 22px 0;
  }

  .widget, .solution-page.team-feature .single-team-feature {
    margin-bottom: 30px;
  }

  .solution-page .single-team-featured-image .team-image {
    margin-bottom: 30px;
    padding: 0 50px;
  }

  .solution-page .team-feature .single-team-feature:after {
    display: none;
  }

  .page-header-area {
    padding: 90px 0;
  }

  .home-about-area, .services-page, .solution-page, .related-team-member, .our-work-three.portfolio-page, .footer-top, .page-header-area {
    padding: 40px 0;
  }

  .contact-page-area {
    padding: 20px 0 !important;
  }

  .home-about-area {
    padding: 80px 0;
  }
}

@media only screen and (width >= 480px) and (width <= 767px) {
  .container {
    width: 450px;
  }

  .mobile-menu-area {
    display: block;
  }

  .page-header-area .title1 h1 {
    padding: 15px 0;
    font-size: 1.2rem;
  }

  .page-header-area .botton ul li.active a, .page-header-area .botton ul li a {
    padding: 10px;
    font-size: 1rem;
  }

  .header-area {
    display: none;
  }

  .header-area .menu-right ul li {
    width: 31px;
  }

  .header-area .menu-right ul li:after {
    height: 26px;
    top: 35px;
  }

  .header-area .main-menu ul li {
    padding: 22px;
  }

  .menu-right {
    display: none;
  }

  .header-area {
    position: inherit;
    background: #000;
  }

  .header-area .logo-area a {
    padding: 5px;
  }

  .related-team-member .related-team .single-team .team-image {
    height: 450px;
    padding: 30px;
  }

  .home-testing .single-team .team-image img {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    padding: 0 50px;
  }

  .padding-481 {
    margin-bottom: 0 !important;
  }

  .home-about-area .about-content .about-content-list .single-list .media .pull-left span, .single-list {
    text-align: center;
    margin: 20px auto;
  }

  .home-about-area .about-content .about-content-list .single-list .media .pull-left, .solution-page .team-feature .single-team-feature .media .pull-left {
    text-align: center;
    margin-right: 0;
    display: block;
    float: inherit !important;
  }

  .solution-page .team-feature .single-team-feature .media .pull-left i {
    margin: auto;
  }

  .our-work-three .work-column-menu ul li {
    margin-bottom: 10px;
    margin-right: 0;
    display: block;
  }

  .contact-page-area .total-contact-form .contact-information {
    padding: 30px;
  }

  .footer-top .widget .social-media ul li {
    margin: 1px;
  }

  .solution-page, .solution-page .single-team-content-area .team-information ul, .about-content {
    text-align: center;
  }

  .contact-page-area .total-contact-form .contact-form h3:after {
    margin: auto;
    left: 0;
    right: 0;
  }

  .contact-page-area .total-contact-form .contact-form {
    margin-left: 20px;
  }

  .footer-top .widget.single-footer2 ul li {
    margin: 22px 0;
  }

  .widget, .solution-page .team-feature .single-team-feature {
    margin-bottom: 30px;
  }

  .padding-480 {
    padding-top: 30px;
  }

  .solution-page .single-team-featured-image .team-image {
    margin-bottom: 30px;
  }

  .solution-page .team-feature .single-team-feature:after {
    display: none;
  }

  .page-header-area {
    padding: 50px 0;
  }

  .home-about-area, .services-page, .solution-page, .related-team-member, .our-work-three.portfolio-page, .footer-top, .contact-page-area, .page-header-area {
    padding: 50px 0 !important;
  }

  .contact-page-area {
    padding: 20px 0 !important;
  }

  .contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
    width: 100%;
  }

  .home-about-area {
    padding: 80px 0;
  }

  .our-work-three {
    padding: 100px 0 0;
  }

  .last-child {
    margin: 0 !important;
    padding: 0 !important;
  }

  .services-page .single-service {
    text-align: center;
    padding: 15px;
  }

  .pagination-area {
    padding-top: 30px;
  }

  .pagination-area ul {
    text-align: center;
  }
}

@media only screen and (width >= 321px) and (width <= 479px) {
  .container {
    width: 300px;
  }

  .mobile-menu-area {
    display: block;
  }

  .contact-page-area .total-contact-form .contact-form {
    margin-left: 20px;
  }

  .related-team-member .related-team .single-team .team-image {
    height: 300px;
  }

  .home-testing .single-team .team-image img {
    width: 100%;
    height: 100%;
    margin-top: 10px;
  }

  .header-area {
    display: none;
  }

  .header-area .menu-right ul li {
    width: 31px;
  }

  .header-area .menu-right ul li:after {
    height: 26px;
    top: 35px;
  }

  .page-header-area .botton ul li.active a, .page-header-area .botton ul li a {
    padding: 10px;
    font-size: 1rem;
  }

  .header-area .main-menu ul li {
    padding: 22px;
  }

  .menu-right {
    display: none;
  }

  .header-area {
    position: inherit;
    background: #000;
  }

  .header-area .logo-area a {
    padding: 5px;
  }

  .page-header-area .title1 h1 {
    width: 20rem;
    font-size: 1.2rem;
  }

  .home-about-area .about-content h3 {
    font-size: 25px;
  }

  .section-area h2 {
    font-size: 29px;
  }

  .home-about-area .about-content .about-content-list .single-list .media .pull-left span, .single-list {
    text-align: center;
    margin: 20px auto;
  }

  .home-about-area .about-content .about-content-list .single-list .media .pull-left, .solution-page .team-feature .single-team-feature .media .pull-left {
    text-align: center;
    margin-right: 0;
    display: block;
    float: inherit !important;
  }

  .solution-page .team-feature .single-team-feature .media .pull-left i {
    margin: auto;
  }

  .contact-page-area .total-contact-form .contact-information {
    padding: 15px;
  }

  .footer-top .widget .social-media ul li {
    margin: 1px;
  }

  .solution-page, .solution-page .single-team-content-area .team-information ul, .about-content {
    text-align: center;
  }

  .contact-page-area .total-contact-form .contact-form h3:after {
    margin: auto;
    left: 0;
    right: 0;
  }

  .footer-top .widget.single-footer2 ul li {
    margin: 22px 0;
  }

  .widget, .solution-page .team-feature .single-team-feature {
    margin-bottom: 30px;
  }

  .padding-480 {
    padding-top: 30px;
  }

  .solution-page .single-team-featured-image .team-image {
    margin-bottom: 30px;
  }

  .solution-page .team-feature .single-team-feature:after {
    display: none;
  }

  .contact-page-area .total-contact-form .contact-form h3 {
    font-size: 20px;
  }

  .page-header-area {
    padding: 60px 0;
  }

  .home-about-area, .services-page, .solution-page, .related-team-member, .our-work-three.portfolio-page, .footer-top {
    padding: 30px 0 !important;
  }

  .contact-page-area {
    padding: 10px 0 !important;
  }

  .our-work-three .work-column-menu ul li {
    margin-bottom: 10px;
    margin-right: 0;
    display: block;
  }

  .related-team-member .related-team .single-team .team-image .overley .team-info ul li a {
    width: 37px;
    height: 37px;
    line-height: 37px;
  }

  .contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
    width: 100%;
  }

  .home-about-area {
    padding: 80px 0;
  }

  .our-work-three {
    padding: 60px 0 0;
  }

  .last-child {
    margin: 0 !important;
    padding: 0 !important;
  }

  .services-page .single-service {
    text-align: center;
    padding: 15px;
  }

  .pagination-area {
    padding-top: 30px;
  }

  .pagination-area ul {
    text-align: center;
  }

  .single-service.padding-481 {
    margin: 0;
  }

  .contact-page-area .total-contact-form .contact-form {
    text-align: center;
    padding: 20px 0;
  }

  .page-header-area h1 {
    margin-bottom: 10px;
    font-size: 24px;
  }
}

@media (width <= 320px) {
  .page-header-area .title1 h1 {
    width: 20rem;
    font-size: 1rem;
  }

  .container {
    width: 300px;
  }

  .mobile-menu-area {
    display: block;
  }

  .header-area {
    display: none;
  }

  .header-area .menu-right ul li {
    width: 31px;
  }

  .header-area .menu-right ul li:after {
    height: 26px;
    top: 35px;
  }

  .header-area .main-menu ul li {
    padding: 22px;
  }

  .menu-right {
    display: none;
  }

  .header-area {
    position: inherit;
    background: #000;
  }

  .header-area .logo-area a {
    padding: 5px;
  }

  .home-about-area .about-content h3 {
    font-size: 25px;
  }

  .section-area h2 {
    font-size: 29px;
  }

  .home-about-area .about-content .about-content-list .single-list .media .pull-left span, .single-list {
    text-align: center;
    margin: 20px auto;
  }

  .home-about-area .about-content .about-content-list .single-list .media .pull-left, .solution-page .team-feature .single-team-feature .media .pull-left {
    text-align: center;
    margin-right: 0;
    display: block;
    float: inherit !important;
  }

  .solution-page .team-feature .single-team-feature .media .pull-left i {
    margin: auto;
  }

  .contact-page-area .total-contact-form .contact-information {
    padding: 25px;
  }

  .footer-top .widget .social-media ul li {
    margin: 1px;
  }

  .solution-page, .solution-page .single-team-content-area .team-information ul, .about-content {
    text-align: center;
  }

  .contact-page-area .total-contact-form .contact-form h3:after {
    margin: auto;
    left: 0;
    right: 0;
  }

  .footer-top .widget.single-footer2 ul li {
    margin: 22px 0;
  }

  .widget, .solution-page .team-feature .single-team-feature {
    margin-bottom: 30px;
  }

  .padding-480 {
    padding-top: 30px;
  }

  .solution-page .single-team-featured-image .team-image {
    margin-bottom: 30px;
  }

  .solution-page .team-feature .single-team-feature:after {
    display: none;
  }

  .contact-page-area .total-contact-form .contact-form h3 {
    font-size: 20px;
  }

  .contact-page-area .total-contact-form .contact-form {
    padding: 15px 0;
  }

  .page-header-area {
    padding: 60px 0;
  }

  .home-about-area, .services-page, .solution-page, .related-team-member, .our-work-three.portfolio-page, .footer-top {
    padding: 60px 0 !important;
  }

  .contact-page-area {
    padding: 10px 0 !important;
  }

  .our-work-three .work-column-menu ul li {
    margin-bottom: 10px;
    margin-right: 0;
    display: block;
  }

  .related-team-member .related-team .single-team .team-image .overley .team-info ul li a {
    width: 37px;
    height: 37px;
    line-height: 37px;
  }

  .contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
    width: 100%;
  }

  .home-about-area {
    padding: 80px 0;
  }

  .our-work-three {
    padding: 60px 0 0;
  }

  .last-child {
    margin: 0 !important;
    padding: 0 !important;
  }

  .services-page .single-service {
    text-align: center;
    padding: 15px;
  }

  .pagination-area {
    padding-top: 30px;
  }

  .pagination-area ul {
    text-align: center;
  }

  .single-service.padding-481 {
    margin: 0;
  }

  .contact-page-area .total-contact-form .contact-form {
    text-align: center;
    padding: 40px 0;
  }

  .page-header-area h1 {
    margin-bottom: 10px;
    font-size: 24px;
  }
}
/*# sourceMappingURL=index.d20f80b1.css.map */
