/* Large desktop :992px. */
@media (min-width: 1170px) and (max-width: 1300px) {

    /* header area styling */
    .header-area .menu-right ul li {
        width: 31px;
    }

    .header-area .menu-right ul li::after {
        height: 26px;
        top: 35px;
    }

    .home-testing .single-team .team-image img {
        padding: 15px;
        width: 100%;
        height: 100%;

        /* objec-fit: contain; */

    }

    /* slider area styling */

    /* home page Styling */

    /* others pages styling */

    .contact-page-area .total-contact-form .contact-information {
        padding: 40px;
    }

    /* responsive issue fix */

    .home-about-area {
        padding: 60px 0;
    }

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {

    /* header area styling */
    .header-area .menu-right ul li {
        width: 31px;
    }

    .header-area .menu-right ul li::after {
        height: 26px;
        top: 35px;
    }

    .header-area .main-menu ul li {
        padding: 35px 22px;
    }

    /* slider area styling */

    .related-team-member .related-team .single-team .team-image {

        /* position: relative;

        padding: 0 20px; */
        height: 300px;
    }


    .home-testing .single-team .team-image img {
        padding: 15px;
        width: 100%;
        height: 100%;

        /* objec-fit: contain; */

    }

    /* home page Styling */

    /* others pages styling */

    .contact-page-area .total-contact-form .contact-information {
        padding: 20px;
    }

    .footer-top .widget .social-media ul li {
        margin: 1px;
    }

    /* responsive issue fix */

    .home-about-area {
        padding: 70px 0;
    }



}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 750px;
    }

    .page-header-area .title1 h1 {

        width: 25em;
        font-size: 1.5rem;
    }

    /* header area styling */
    .header-area .menu-right ul li {
        width: 31px;
    }

    .header-area .menu-right ul li::after {
        height: 26px;
        top: 35px;
    }

    .header-area .main-menu ul li {
        padding: 22px;
    }

    .menu-right {
        display: none;
    }

    .header-area {
        position: inherit;
        background: #000000;
    }

    .header-area .logo-area a {
        padding: 5px;
    }

    .related-team-member .related-team .single-team .team-image {

        /* position: relative;

        padding: 0 20px; */
        height: 370px;


    }


    .home-testing .single-team .team-image img {
        padding: 15px;
        width: 100%;
        height: 100%;

        /* objec-fit: contain; */

    }

    /* slider area styling */

    /* home page Styling */

    /* others pages styling */

    .contact-page-area .total-contact-form .contact-information {
        padding: 30px;
    }

    .footer-top .widget .social-media ul li {
        margin: 1px;
    }


    .solution-page,
    .solution-page .single-team-content-area .team-information ul {
        text-align: center;
    }

    .footer-top .widget.single-footer2 ul li {
        margin: 22px 0;
    }

    .widget,
    .solution-page.team-feature .single-team-feature {
        margin-bottom: 30px;
    }

    .solution-page .single-team-featured-image .team-image {
        padding: 0 50px;
        margin-bottom: 30px;
    }

    .solution-page .team-feature .single-team-feature::after {
        display: none;
    }

    /*==== Start gallery masonary v1 area css ====*/

    /* responsive spacing issues */


    .page-header-area {
        padding: 90px 0;
    }


    .home-about-area,
    .services-page,
    .solution-page,
    .related-team-member,
    .our-work-three.portfolio-page,
    .footer-top,
    .page-header-area {
        padding: 40px 0;
    }

    .contact-page-area {
        padding: 20px 0 !important;
    }

    /* responsive issue fix */

    .home-about-area {
        padding: 80px 0;
    }


}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .container {
        width: 450px;
    }

    .mobile-menu-area {
        display: block;
    }

    .page-header-area .title1 h1 {

        padding: 15px 0;
        font-size: 1.2rem;
    }

    .page-header-area .botton ul li.active a {
        font-size: 1rem;
        padding: 10px 10px;
    }

    .page-header-area .botton ul li a {
        font-size: 1rem;
        padding: 10px 10px;
    }

    .header-area {
        display: none;
    }

    /* header area styling */
    .header-area .menu-right ul li {
        width: 31px;
    }

    .header-area .menu-right ul li::after {
        height: 26px;
        top: 35px;
    }

    .header-area .main-menu ul li {
        padding: 22px;
    }

    .menu-right {
        display: none;
    }

    .header-area {
        position: inherit;
        background: #000000;
    }

    .header-area .logo-area a {
        padding: 5px;
    }

    .related-team-member .related-team .single-team .team-image {

        height: 450px;
        padding: 30px;

    }


    .home-testing .single-team .team-image img {
        padding: 0 50px;
        width: 100%;
        height: 100%;
        margin-top: 10px;
        /* objec-fit: contain; */

    }

    /* slider area styling */

    /* home page Styling */

    .padding-481 {
        margin-bottom: 0px !important;
    }

    .home-about-area .about-content .about-content-list .single-list .media .pull-left span,
    .single-list {
        margin: 20px auto;
        text-align: center;
    }

    .home-about-area .about-content .about-content-list .single-list .media .pull-left,
    .solution-page .team-feature .single-team-feature .media .pull-left {
        display: block;
        float: inherit !important;
        text-align: center;
        margin-right: 0;
    }

    .solution-page .team-feature .single-team-feature .media .pull-left i {
        margin: auto;
    }

    .our-work-three .work-column-menu ul li {
        margin-bottom: 10px;
        margin-right: 0;
        display: block;
    }




    /* others pages styling */

    .contact-page-area .total-contact-form .contact-information {
        padding: 30px;
    }

    .footer-top .widget .social-media ul li {
        margin: 1px;
    }


    .solution-page,
    .solution-page .single-team-content-area .team-information ul,
    .about-content {
        text-align: center;
    }


    .contact-page-area .total-contact-form .contact-form h3::after {
        left: 0;
        margin: auto;
        right: 0;
    }

    .contact-page-area .total-contact-form .contact-form {
        margin-left: 20px;
    }

    .footer-top .widget.single-footer2 ul li {
        margin: 22px 0;
    }

    .widget,
    .solution-page .team-feature .single-team-feature {
        margin-bottom: 30px;
    }

    .padding-480 {
        padding-top: 30px;
    }

    .solution-page .single-team-featured-image .team-image {
        margin-bottom: 30px;
    }

    .solution-page .team-feature .single-team-feature::after {
        display: none;
    }

    /*==== Start gallery masonary v1 area css ====*/

    /* responsive spacing issues */


    .page-header-area {
        padding: 50px 0;
    }


    .home-about-area,
    .services-page,
    .solution-page,
    .related-team-member,
    .our-work-three.portfolio-page,
    .footer-top,
    .contact-page-area,
    .page-header-area {
        padding: 50px 0 !important;
    }

    .contact-page-area {

        padding: 20px 0 !important;
    }

    .contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
        width: 100%;
    }

    /* responsive issue fix */

    .home-about-area {
        padding: 80px 0;
    }

    .our-work-three {
        padding: 100px 0 0;
    }

    .last-child {
        margin: 0 !important;
        padding: 0 !important;
    }

    .services-page .single-service {
        padding: 15px;
        text-align: center;
    }

    .pagination-area {
        padding-top: 30px;
    }

    .pagination-area ul {
        text-align: center;
    }



}

/* mobile device :320px. */
@media only screen and (min-width: 321px) and (max-width:479px) {
    .container {
        width: 300px;
    }

    .mobile-menu-area {
        display: block;
    }

    .contact-page-area .total-contact-form .contact-form {
        margin-left: 20px;
    }

    .related-team-member .related-team .single-team .team-image {

        /* position: relative;

        padding: 0 20px; */
        height: 300px;


    }

    .home-testing .single-team .team-image img {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        /* objec-fit: contain; */

    }

    .header-area {
        display: none;
    }

    /* header area styling */
    .header-area .menu-right ul li {
        width: 31px;
    }

    .header-area .menu-right ul li::after {
        height: 26px;
        top: 35px;
    }


    .page-header-area .botton ul li.active a {
        font-size: 1rem;
        padding: 10px 10px;
    }

    .page-header-area .botton ul li a {
        font-size: 1rem;
        padding: 10px 10px;
    }

    .header-area .main-menu ul li {
        padding: 22px;
    }

    .menu-right {
        display: none;
    }

    .header-area {
        position: inherit;
        background: #000000;
    }

    .header-area .logo-area a {
        padding: 5px;
    }

    /* slider area styling */

    .page-header-area .title1 h1 {
        width: 20rem;
        font-size: 1.2rem;
    }

    .home-about-area .about-content h3 {
        font-size: 25px;
    }

    .section-area h2 {
        font-size: 29px;
    }

    .home-about-area .about-content .about-content-list .single-list .media .pull-left span,
    .single-list {
        margin: 20px auto;
        text-align: center;
    }

    .home-about-area .about-content .about-content-list .single-list .media .pull-left,
    .solution-page .team-feature .single-team-feature .media .pull-left {
        display: block;
        float: inherit !important;
        text-align: center;
        margin-right: 0;
    }

    .solution-page .team-feature .single-team-feature .media .pull-left i {
        margin: auto;
    }




    /* others pages styling */

    .contact-page-area .total-contact-form .contact-information {
        padding: 15px;
    }

    .footer-top .widget .social-media ul li {
        margin: 1px;
    }


    .solution-page,
    .solution-page .single-team-content-area .team-information ul,
    .about-content {
        text-align: center;
    }


    .contact-page-area .total-contact-form .contact-form h3::after {
        left: 0;
        margin: auto;
        right: 0;
    }

    .footer-top .widget.single-footer2 ul li {
        margin: 22px 0;
    }

    .widget,
    .solution-page .team-feature .single-team-feature {
        margin-bottom: 30px;
    }

    .padding-480 {
        padding-top: 30px;
    }

    .solution-page .single-team-featured-image .team-image {
        margin-bottom: 30px;
    }

    .solution-page .team-feature .single-team-feature::after {
        display: none;
    }


    .pagination-area ul {
        text-align: center;
    }

    .contact-page-area .total-contact-form .contact-form h3 {
        font-size: 20px;
    }


    .services-page .single-service {
        padding: 27px;
        text-align: center;
    }


    /*==== Start gallery masonary v1 area css ====*/

    /* responsive spacing issues */


    .page-header-area {
        padding: 60px 0;
    }


    .home-about-area,
    .services-page,
    .solution-page,
    .related-team-member,
    .our-work-three.portfolio-page,
    .footer-top {
        padding: 30px 0 !important;
    }

    .contact-page-area {
        padding: 10px 0 !important;
    }

    .our-work-three .work-column-menu ul li {
        margin-bottom: 10px;
        margin-right: 0;
        display: block;
    }

    .related-team-member .related-team .single-team .team-image .overley .team-info ul li a {
        width: 37px;
        height: 37px;
        line-height: 37px;
    }

    .contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
        width: 100%;
    }

    /* responsive issue fix */

    .home-about-area {
        padding: 80px 0;
    }

    .our-work-three {
        padding: 60px 0 0;
    }

    .last-child {
        margin: 0 !important;
        padding: 0 !important;
    }

    .services-page .single-service {
        padding: 15px;
        text-align: center;
    }

    .pagination-area {
        padding-top: 30px;
    }

    .pagination-area ul {
        text-align: center;
    }

    .single-service.padding-481 {
        margin: 0;
    }

    .contact-page-area .total-contact-form .contact-form {
        padding: 20px 0;
        text-align: center;
    }

    .page-header-area h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }
}

/* mobile device :320px. */
@media (max-width: 320px) {

    .page-header-area .title1 h1 {

        width: 20rem;
        font-size: 1rem;
    }

    .container {
        width: 300px;
    }

    .mobile-menu-area {
        display: block;
    }

    .header-area {
        display: none;
    }

    /* header area styling */
    .header-area .menu-right ul li {
        width: 31px;
    }

    .header-area .menu-right ul li::after {
        height: 26px;
        top: 35px;
    }

    .header-area .main-menu ul li {
        padding: 22px;
    }

    .menu-right {
        display: none;
    }

    .header-area {
        position: inherit;
        background: #000000;
    }

    .header-area .logo-area a {
        padding: 5px;
    }

    /* slider area styling */

    /* home page Styling */

    .home-about-area .about-content h3 {
        font-size: 25px;
    }

    .section-area h2 {
        font-size: 29px;
    }

    .home-about-area .about-content .about-content-list .single-list .media .pull-left span,
    .single-list {
        margin: 20px auto;
        text-align: center;
    }

    .home-about-area .about-content .about-content-list .single-list .media .pull-left,
    .solution-page .team-feature .single-team-feature .media .pull-left {
        display: block;
        float: inherit !important;
        text-align: center;
        margin-right: 0;
    }

    .solution-page .team-feature .single-team-feature .media .pull-left i {
        margin: auto;
    }




    /* others pages styling */

    .contact-page-area .total-contact-form .contact-information {
        padding: 25px;
    }

    .footer-top .widget .social-media ul li {
        margin: 1px;
    }


    .solution-page,
    .solution-page .single-team-content-area .team-information ul,
    .about-content {
        text-align: center;
    }


    .contact-page-area .total-contact-form .contact-form h3::after {
        left: 0;
        margin: auto;
        right: 0;
    }

    .footer-top .widget.single-footer2 ul li {
        margin: 22px 0;
    }

    .widget,
    .solution-page .team-feature .single-team-feature {
        margin-bottom: 30px;
    }

    .padding-480 {
        padding-top: 30px;
    }

    .solution-page .single-team-featured-image .team-image {

        margin-bottom: 30px;
    }

    .solution-page .team-feature .single-team-feature::after {
        display: none;
    }


    .pagination-area ul {
        text-align: center;
    }

    .contact-page-area .total-contact-form .contact-form h3 {
        font-size: 20px;
    }

    .contact-page-area {
        padding: 20px 0;
    }

    .services-page .single-service {
        padding: 27px;
        text-align: center;
    }

    .contact-page-area .total-contact-form .contact-form {
        padding: 15px 0;
    }

    /*==== Start gallery masonary v1 area css ====*/

    /* responsive spacing issues */


    .page-header-area {
        padding: 60px 0;
    }


    .home-about-area,
    .services-page,
    .solution-page,
    .related-team-member,
    .our-work-three.portfolio-page,
    .footer-top {
        padding: 60px 0 !important;
    }

    .contact-page-area {
        padding: 10px 0 !important;
    }

    .our-work-three .work-column-menu ul li {
        margin-bottom: 10px;
        margin-right: 0;
        display: block;
    }

    .related-team-member .related-team .single-team .team-image .overley .team-info ul li a {
        width: 37px;
        height: 37px;
        line-height: 37px;
    }

    .contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
        width: 100%;
    }

    /* responsive issue fix */

    .home-about-area {
        padding: 80px 0;
    }

    .our-work-three {
        padding: 60px 0 0;
    }

    .last-child {
        margin: 0 !important;
        padding: 0 !important;
    }

    .services-page .single-service {
        padding: 15px;
        text-align: center;
    }

    .pagination-area {
        padding-top: 30px;
    }

    .pagination-area ul {
        text-align: center;
    }

    .single-service.padding-481 {
        margin: 0;
    }

    .contact-page-area .total-contact-form .contact-form {
        padding: 40px 0;
        text-align: center;
    }

    .page-header-area h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }
}